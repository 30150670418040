import React, { useRef } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import headerImage from "./assets/header.png";
import { jsPDF } from "jspdf";
import SignatureCanvas from "react-signature-canvas";

function Questionnaire() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const signatureCanvasRef = useRef(null);

  const onSubmit = (data) => {
    // Get the signature data as a base64-encoded image
    const signatureImage = signatureCanvasRef.current.toDataURL();

    // Include the signature in the form data
    data.signature = signatureImage;

    // const pdf = new jsPDF();
    // // Add content to the PDF document
    // pdf.text("Questionnaire Data", 10, 10); // Add a title
    // pdf.text(JSON.stringify(data), 10, 20); // Add the form data as JSON

    // // Save the PDF as a file
    // pdf.save("questionnaire.pdf");
  };

  return (
    <div className="questionnaire-container">
      <div className="header-container">
        <div className="header-text">
          <h1>
            Questionnaire for Skin Cancer <br />
            Screening and Mole Mapping
          </h1>
        </div>
        <img
          src={headerImage}
          alt="Questionnaire"
          className="questionnaire-image"
        />
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-section">
          <div className="questionare-data questionare-data3">
            <div className="column-left">
              <h2>Patient Details</h2>
            </div>
            <div className="divider"></div>
            <div className="column-right">
              <label className="questionare-label">Date:</label>
              <input {...register("date", { required: true })} type="date" />
            </div>
          </div>

          <div className="questionare-data"></div>
          <div className="questionare-data">
            <label className="questionare-label">Full Name:</label>
            <input {...register("full_name", { required: true })} type="text" />
          </div>
          <div className="questionare-data">
            <label className="questionare-label">Date of birth:</label>
            <input
              {...register("dateOfBirth", { required: true })}
              type="date"
            />
          </div>
          <div className="questionare-data">
            <label className="questionare-label">Tel number:</label>
            <input {...register("telNumber", { required: true })} type="tel" />
          </div>
          <div className="questionare-data">
            <label className="questionare-label">Email address:</label>
            <input {...register("email")} type="email" />
          </div>

          <div className="questionare-data questionare-data2">
            <div className="column-left">
              <label className="questionare-label">
                May we contact you about upcoming appointments, treatment
                reminders, and follow-ups to keep you informed?:
              </label>
              <input {...register("appointments-text")} type="text" />
            </div>
            <div className="divider"></div>
            <div className="column-right">
              <input {...register("radioGroup")} type="radio" value="option1" />{" "}
              Yes
              <input
                {...register("radioGroup")}
                type="radio"
                value="option2"
              />{" "}
              NO
            </div>
          </div>
        </div>
        <h2>Please answer the following questions:</h2>
        <div className="form-section">
          <div className="questionare-data questionare-data4">
            <label className="questionare-label">
              Have you been{" "}
              <span className="underline-text">examined or screened</span> for
              skin cancers before? If so, when and who did you see?
            </label>
            <br />
            <input {...register("examination")} type="text" />
          </div>

          <div className="questionare-data4 ">
            <label className="questionare-label">
              Have you had any skin cancers / abnormal lesions{" "}
              <span className="underline-text">removed</span> before? When and
              where?
            </label>
            <br />
            <input {...register("examination")} type="text" />
          </div>

          <div className="questionare-data3">
            <div className="column-left">
              <label className="questionare-label">
                Do you have a{" "}
                <span className="underline-text">
                  personal history of melanoma
                </span>{" "}
                ? When?
              </label>
              <input {...register("melanoma-history")} type="text" />
            </div>
            <div className="divider"></div>
            <div className="column-right">
              <input {...register("radioGroup")} type="radio" value="option1" />{" "}
              Yes
              <input
                {...register("radioGroup")}
                type="radio"
                value="option2"
              />{" "}
              NO
            </div>
          </div>

          <div className="questionare-data questionare-data3">
            <div className="column-left">
              <label className="questionare-label">
                Is there any history of{" "}
                <span className="underline-text">melanoma in the family</span> ?
                Who?
              </label>
              <input {...register("melanoma-family")} type="text" />
            </div>
            <div className="divider"></div>
            <div className="column-right">
              <input {...register("radioGroup")} type="radio" value="option1" />{" "}
              Yes
              <input
                {...register("radioGroup")}
                type="radio"
                value="option2"
              />{" "}
              NO
            </div>
          </div>
        </div>
        <div className="form-section">
          <h3>Informed Consent</h3>
          <p>
            The above procedure (mole-mapping and digital imaging) that is
            proposed in my/our child`s case has been fully explained to me/us in
            a patient-doctor discussion with Dr Stasch. I was/we were afforded
            the opportunity to ask any question I/we deemed important regarding
            the nature and the purpose of the procedure, the specific associated
            risks and possible complications, any additional/subsequent
            procedures/treatment that may be needed and any alternative forms of
            screening/treatment that may be available.
            <br></br>
            I/We have no further questions and feel that the information
            provided was satisfactory; therefore, I/we consent to the proposed
            procedure. I/We further consent to any unforeseen
            additional/subsequent procedures/treatments that may become
            necessary from a medical point of view
          </p>
          <div className="centered-input-container">
            <input
              {...register("signatureDate", { required: true })}
              type="date"
              className="center-width-input"
            />
          </div>

          <h3>Date</h3>
          <div className="centered-input-container">
            {/* <input
              {...register("signaturePatient", { required: true })}
              type="text"
              className="center-width-input"
            /> */}
            <SignatureCanvas
              ref={signatureCanvasRef}
              // ref={(ref) => {
              //   // This ref allows you to interact with the signature canvas
              //   this.signatureCanvas = ref;
              // }}
              penColor="#522D1E" // Set the pen color to #522D1E
              canvasProps={{
                width: 300,
                height: 100,
                style: { border: "2px solid #522D1E" },
              }} // Set canvas dimensions
            />
          </div>
          {/* <button
            type="button"
            onClick={() => {
              this.signatureCanvas.clear();
            }}
          >
            Clear Signature
          </button> */}

          <h3>Patient's/ parents' signature</h3>
        </div>
        {/* <h2 className="questionare-data">
          Please indicate here which lesions on your skin concern you:
        </h2> */}
        <div className="submit-btn-container">
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default Questionnaire;
